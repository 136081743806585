<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="stores"
      :items-per-page="15"
      :search="search"
      @click:row="openStoreDetails"
      show-group-by
      loading-text="Tunggu sebentar"
      no-data-text="Tidak ada data konter."
      no-results-text="Data tidak ditemukan"
      class="elevation-1"
    >

      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Konter</v-toolbar-title>
          <v-spacer/>
          <v-text-field
            style="width: 15%; max-width: 300px"
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari"
            single-line
            hide-details
          ></v-text-field>
          <DownloadStoreExcel class="ml-2" :stores="stores"/>
          <DownloadStorePdf class="ml-2" :stores="stores"/>
        </v-toolbar>
      </template>
    </v-data-table>

    <!-- Store Details Dialog -->
    <v-dialog
      v-model="storeDetailsDialog"
      width="70%"
      max-width="50%">
      <v-card>
        <v-row no-gutters>
          <v-img
            height="100%"
            :src="viewedStore.photoUrl"
          />
        </v-row>
        <v-row no-gutters>
          <v-col class="pa-8" style="overflow-y: auto">
            <v-row>
              <v-col cols="8">
                <span class="text-h3">{{ viewedStore.name }}</span>
              </v-col>
              <v-col cols="4" align-self="center" class="text-end">
                <v-rating
                  background-color="warning lighten-1"
                  color="warning"
                  length="5"
                  dense
                  readonly
                  size="32"
                  :value="viewedStore.paymentRating"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <p class="subtitle-2 ma-0">{{ viewedStore.areaCode }}</p>
                <p class="subtitle-1">{{ viewedStore.address }}</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                Sales
              </v-col>
              <v-col cols="9">
                {{ viewedStore.salesmanId }}
              </v-col>
              <v-col cols="2">
                Catatan
              </v-col>
              <v-col cols="9">
                {{ viewedStore.note }}
              </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row no-gutters>
              <v-col cols="12"><p class="text-h5">Summary hutang dan pembayaran</p></v-col>
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                    <tr
                      v-for="item in viewedStoreStats"
                      :key="item.name"
                    >
                      <td>{{ item.title }}</td>
                      <td>{{ item.value }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row no-gutters>
              <v-col cols="12"><p class="text-h5">Pemilik Konter</p></v-col>
              <v-col cols="2" class="d-flex child-flex mr-4">
                <v-img
                  aspect-ratio="1"
                  :src="viewedStore.ownerPhotoUrl"
                  max-width="100%"/>
              </v-col>
              <v-col>
                <p class="text-h6">{{ viewedStore.ownerName }}</p>
                <v-icon class="mr-2">mdi-phone</v-icon>
                <span>{{ viewedStore.ownerPhone }}</span>
              </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row>
              <v-col>
                <GmapMap
                  :center="{lat:viewedStore.lat, lng:viewedStore.long}"
                  :zoom="15"
                  style="width: 100%; height: 500px"
                >
                  <GmapMarker
                    :position="{lat:viewedStore.lat, lng:viewedStore.long}"></GmapMarker>
                </GmapMap>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { toRupiahString } from '@/utils/currencyString'
import moment from 'moment'
import DownloadStorePdf from '@/components/store/DownloadStorePdf'
import DownloadStoreExcel from '@/components/store/DownloadStoreExcel'

export default {
  name: 'StoreTable',
  components: { DownloadStoreExcel, DownloadStorePdf },
  data: () => ({
    search: '',
    headers: [
      {
        text: 'ID Agen',
        align: 'start',
        value: 'id',
        groupable: false
      },
      {
        text: 'Sales',
        value: 'salesmanId'
      },
      {
        text: 'Kode area',
        value: 'areaCode'
      },
      {
        text: 'Nama konter',
        value: 'name'
      },
      {
        text: 'Pemilik',
        value: 'ownerName'
      }
    ],
    storeDetailsDialog: false,
    viewedStore: {}
  }),

  computed: {
    stores () {
      return this.$store.state.stores
    },
    viewedStoreTransactions () {
      return this.$store.getters.approvedTransactions
        .filter(value => value.agentId === this.viewedStore.id)
    },
    viewedStoreDebt () {
      return this.getStoreDebt()
    },
    viewedStoreLastTwoWeeksSalesAverage () {
      return this.getViewedStoreLastTwoWeekSalesAverage()
    },
    viewedStoreLastTwoWeeksPaymentsAverage () {
      return this.getViewedStoreLastTwoWeekPaymentsAverage()
    },
    viewedStoreLastSalesDate () {
      const lastSales = this.getViewedStoreLastSales()
      if (lastSales !== null) {
        return lastSales.date.toDate().toLocaleDateString()
      } else {
        return 'Tidak ada order'
      }
    },
    viewedStoreLastPaymentDate () {
      const lastPayment = this.getViewedStoreLastPayment()
      if (lastPayment !== null) {
        return lastPayment.date.toDate().toLocaleDateString()
      } else {
        return 'Tidak ada pembayaran'
      }
    },
    viewedStoreStats () {
      return [
        {
          title: 'Total Hutang',
          value: toRupiahString(this.viewedStoreDebt)
        },
        {
          title: 'Rata-rata order selama 2 minggu terakhir',
          value: toRupiahString(this.viewedStoreLastTwoWeeksSalesAverage)
        },
        {
          title: 'Rata-rata pembayaran selama 2 minggu terakhir',
          value: toRupiahString(this.viewedStoreLastTwoWeeksPaymentsAverage)
        },
        {
          title: 'Order terakhir',
          value: this.viewedStoreLastSalesDate
        },
        {
          title: 'Pembayaran terakhir',
          value: this.viewedStoreLastPaymentDate
        }
      ]
    }
  },

  methods: {
    openStoreDetails (item, row) {
      this.viewedStore = item
      this.storeDetailsDialog = true
    },
    toRupiahString (value) {
      return toRupiahString(value)
    },
    getStoreDebt () {
      let debt = 0
      this.viewedStoreTransactions
        .forEach(value => {
          debt += value.amount
          return null
        })
      return debt
    },
    getViewedStoreLastTwoWeekSalesAverage () {
      let sum = 0
      const transactionList = this.viewedStoreTransactions
        .filter(value => value.type === 'SALES')
        .filter(value =>
          moment(value.date.toDate())
            .isAfter(moment().subtract(14, 'day'), 'day'))
      transactionList
        .forEach(value => {
          sum += value.amount
        })
      return sum / transactionList.length || 0
    },
    getViewedStoreLastTwoWeekPaymentsAverage () {
      let sum = 0
      const transactionList = this.viewedStoreTransactions
        .filter(value => value.type === 'PAYMENT')
        .filter(value =>
          moment(value.date.toDate())
            .isAfter(moment().subtract(14, 'day'), 'day'))
      transactionList
        .forEach(value => {
          sum += value.amount
        })
      return sum / transactionList.length || 0
    },
    getViewedStoreLastSales () {
      const storeTransactions = this.viewedStoreTransactions
        .filter(value => value.type === 'SALES')
      const sortedTransactions = storeTransactions
        .sort((a, b) => b.date.toDate() - a.date.toDate())
      return sortedTransactions[0] || null
    },
    getViewedStoreLastPayment () {
      const storeTransactions = this.viewedStoreTransactions
        .filter(value => value.type === 'PAYMENT')
      const sortedTransactions = storeTransactions
        .sort((a, b) => b.date.toDate() - a.date.toDate())
      return sortedTransactions[0] || null
    }
  }
}
</script>
