<template>
  <div>
    <AppBar ref="appBar"/>
    <NavigationDrawer v-if="user !== null" v-bind:account-name="user.name" v-bind:account-role="user.role"/>
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import NavigationDrawer from '@/components/NavigationDrawer'

export default {
  components: {
    NavigationDrawer,
    AppBar
  },
  computed: {
    user () {
      const user = this.$store.state.user
      if (user === 'invalid') this.logout()
      return user
    }
  },
  created () {
    this.checkLoginStatus()
  },
  methods: {
    logout () {
      this.$refs.appBar.logout()
    },
    checkLoginStatus () {
      if (this.$firebase.auth().currentUser) {
        // User is signed in.
        this.$store.dispatch('fetchUid')
        this.$store.dispatch('fetchUserData')
        console.log('user signed in')
      } else {
        // No user is signed in.
        this.$router.replace('/login').catch()
        console.log('not signed in')
      }
    }
  }
}
</script>
