<template>
  <v-container class="body fill-height" fluid>
    <v-row class="ma-0 mt-6 mx-auto justify-center">
      <span class="display-3 font-weight-bold">DFlash</span>
      <span class="display-3">Admin</span>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Main',
  created () {
    this.checkLogin()
  },
  methods: {
    checkLogin () {
      if (this.$firebase.auth().currentUser) {
        // User is signed in.
        this.$router.replace('dashboard')
        console.log('user signed in')
      } else {
        // No user is signed in.
        this.$router.replace('login').catch()
        console.log('not signed in')
      }
    }
  }
}
</script>
