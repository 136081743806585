<template>
  <export-excel
    :data="exportedTransactions"
    :fields="fields"
    name="Transaksi.xls">
    <v-btn color="green">
      Export Excel
    </v-btn>
  </export-excel>
</template>

<script>
import { toRupiahString } from '@/utils/currencyString'

export default {
  name: 'DownloadTransactionsExcel',
  props: {
    transactions: Array
  },
  computed: {
    exportedTransactions () {
      const exported = this.transactions.map(value => ({ ...value }))
      exported.forEach(value => {
        value.type = (value.type === 'SALES') ? 'Penjualan' : 'Pembayaran'
        value.date = value.date.toDate().toLocaleDateString()
        value.amount = toRupiahString(value.amount)
      })
      return exported
    }
  },
  data: () => ({
    fields: {
      'ID Konter': 'agentId',
      'ID Sales': 'salesmanId',
      Tanggal: 'date',
      'Jenis transaksi': 'type',
      Nilai: 'amount',
      Catatan: 'note'
    }
  })
}
</script>
