import * as GmapVue from 'gmap-vue'

const maps = GmapVue
const mapsOptions = {
  load: {
    key: 'AIzaSyDNEYU-zP0mjLiRiOuYQKvEzQzrUpugWQU',
    libraries: 'places',
    region: 'ID',
    language: 'id'
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
  },
  installComponents: true
}

export { maps, mapsOptions }
