<template>
  <v-container class="pa-8">
    <v-row>
      <v-col>
        <SalesManTable></SalesManTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SalesManTable from '@/components/salesman/SalesManTable'

export default {
  name: 'Sales',
  components: { SalesManTable },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  created () {
    if (this.user.role !== 'administrator') this.$router.back()
  }
}
</script>
