<template>
  <v-data-table
    :headers="headers"
    :items="transactions"
    :items-per-page="15"
    :search="search"
    loading-text="Tunggu sebentar"
    no-data-text="Tidak ada data transaksi."
    no-results-text="Data tidak ditemukan"
    class="elevation-1"
  >

    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Transaksi Sudah Disetujui</v-toolbar-title>
        <v-spacer/>
        <v-text-field
          style="width: 15%; max-width: 300px"
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari"
          single-line
          hide-details
        ></v-text-field>
        <DownloadTransactionsPdf :transactions="transactions" class="ml-2"/>
        <DownloadTransactionsExcel :transactions="transactions" class="ml-2"/>
      </v-toolbar>
    </template>
    <template v-slot:item.type="{ item }">
      {{ (item.type === 'PAYMENT') ? 'Order' : 'Pembayaran'}}
    </template>
    <template v-slot:item.date="{ item }">
      {{item.date.toDate().toLocaleDateString()}}
    </template>
    <template v-slot:item.amount="{ item }">
      {{ toRupiahString (item.amount) }}
    </template>
  </v-data-table>
</template>

<script>
import { toRupiahString } from '@/utils/currencyString'
import DownloadTransactionsPdf from '@/components/transaction/DownloadTransactionsPdf'
import DownloadTransactionsExcel from '@/components/transaction/DownloadTransactionsExcel'

export default {
  name: 'TransactionTable',
  components: { DownloadTransactionsExcel, DownloadTransactionsPdf },
  data: () => ({
    search: '',
    headers: [
      {
        text: 'ID Agen',
        align: 'start',
        value: 'agentId'
      },
      {
        text: 'ID Sales',
        align: 'start',
        value: 'salesmanId'
      },
      {
        text: 'Tanggal',
        value: 'date'
      },
      {
        text: 'Jenis transaksi',
        value: 'type'
      },
      {
        text: 'Nilai',
        value: 'amount',
        align: 'end'
      },
      {
        text: 'Catatan',
        value: 'note'
      }
    ]
  }),

  computed: {
    transactions () {
      return this.$store.getters.approvedTransactions
    }
  },
  methods: {
    toRupiahString (value) {
      return toRupiahString(value)
    }
  }
}
</script>
