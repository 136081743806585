<template>
  <v-navigation-drawer
    light
    permanent
    app
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{ accountName }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ accountRole }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list>
        <v-list-item
          v-for="([icon, text, route], i) in items"
          :key="i"
          link
          :to="route"
        >
        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ text }}</v-list-item-title>
        </v-list-item-content>
        </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavigationDrawer',
  props: {
    accountName: String,
    accountRole: String
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  data: () => ({
    items: [
      ['mdi-cash-multiple', 'Transaksi', '/dashboard/transaction'],
      ['mdi-store', 'Konter', '/dashboard/store'],
      ['mdi-access-point', 'Pengumuman', '/dashboard/announcements'],
      ['mdi-shield-account', 'Admin', '/dashboard/admin'],
      ['mdi-face-shimmer', 'Sales', '/dashboard/sales']
    ]
  }),
  mounted () {
    if (this.user.role !== 'administrator') {
      this.items = this.items.slice(0, 3)
      console.log()
    }
  }
}
</script>
