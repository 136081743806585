<template>
  <v-data-table
    :headers="headers"
    :items="admins"
    :items-per-page="15"
    :loading="this.isLoading"
    loading-text="Tunggu sebentar"
    no-data-text="Tidak ada admin."
    no-results-text="Data tidak ditemukan"
    class="elevation-1"
  >

    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Daftar Admin</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>

        <!--Refresh button-->
        <v-btn
          icon
          color="blue"
          class="mr-2"
          @click="fetchSalesmanData(true)"
        >
          <v-icon>mdi-cached</v-icon>
        </v-btn>

        <!--Add and Edit dialog-->
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Tambahkan Admin
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-form
              v-model="validForm">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.username"
                        :disabled="editedIndex > -1"
                        label="Username"
                        :rules="usernameRules"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.name"
                        label="Nama"
                        :rules="nameRules"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="editedIndex <= -1"
                    >
                      <v-text-field
                        v-model="editedItem.password"
                        label="Password"
                        :rules="passwordRules"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="editedIndex <= -1"
                    >
                      <v-autocomplete
                        v-model="editedItem.role"
                        label="Role"
                        :items="roles"
                        :rules="nameRules"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeAddEditDialog"
                >
                  Batal
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  :disabled="!validForm"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <!--Delete dialog-->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Anda yakin ingin menghapus admin ini?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDeleteDialog">Batal</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        @click="showDeleteDialog(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import * as inputValidation from '@/utils/inputRules'

export default {
  name: 'AdminTable',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    isLoading: false,
    usernameRules: inputValidation.usernameRule,
    passwordRules: inputValidation.passwordRule,
    nameRules: inputValidation.salesNameRule,
    validForm: false,
    headers: [
      {
        text: 'Username',
        align: 'start',
        value: 'username'
      },
      {
        text: 'Nama',
        value: 'name'
      },
      {
        text: 'Role',
        value: 'role'
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false
      }
    ],
    roles: ['administrator', 'admin'],
    editedIndex: -1,
    editedItem: {
      username: '',
      name: '',
      password: '',
      role: ''
    },
    defaultItem: {
      username: '',
      name: '',
      password: '',
      role: ''
    }
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Tambahkan Admin' : 'Edit Admin'
    },
    admins () {
      return this.$store.state.admins || []
    }
  },

  watch: {
    dialog (val) {
      val || this.closeAddEditDialog()
    },
    dialogDelete (val) {
      val || this.closeDeleteDialog()
    }
  },

  created () {
    this.fetchAdminDataList(false)
  },

  methods: {
    showAddEditDialog (item) {
      this.editedIndex = this.admins.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    showDeleteDialog (item) {
      this.editedIndex = this.admins.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    closeAddEditDialog () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDeleteDialog () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    deleteItemConfirm () {
      this.deleteAdminAccount()
      this.closeDeleteDialog()
    },

    fetchAdminDataList (forceUpdate) {
      this.$store.dispatch('fetchAdminDataList', { forceUpdate: forceUpdate })
    },

    save () {
      this.createAdminAccount()
      this.closeAddEditDialog()
    },

    createAdminAccount () {
      this.isLoading = true
      const createAdmin = this.$firebase.functions('asia-southeast2').httpsCallable('createAdminAccount')
      createAdmin({
        username: this.editedItem.username,
        name: this.editedItem.name,
        password: this.editedItem.password,
        role: this.editedItem.role
      })
        .then(result => {
          console.log('Success creating account:', result.data)
          this.fetchAdminDataList(true)
          this.isLoading = false
        })
        .catch(error => {
          console.error(error)
          this.isLoading = false
        })
    },

    deleteAdminAccount () {
      this.isLoading = true
      const deleteAdmin = this.$firebase.functions('asia-southeast2').httpsCallable('deleteAdminAccount')
      deleteAdmin({
        username: this.editedItem.username,
        name: this.editedItem.name,
        password: this.editedItem.password,
        role: this.editedItem.role
      })
        .then(result => {
          console.log('Success deleting account', result)
          this.fetchAdminDataList(true)
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
        })
    }
  }
}
</script>
