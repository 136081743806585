<template>
  <v-data-table
    :headers="headers"
    :items="announcements"
    :items-per-page="15"
    loading-text="Tunggu sebentar"
    no-data-text="Tidak ada pengumuman."
    no-results-text="Data tidak ditemukan"
    class="elevation-1"
  >

    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Pengumuman</v-toolbar-title>
        <v-spacer/>

        <!--    Add Announcement Dialog    -->
        <v-dialog
          v-model="dialog"
          max-width="40%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="primary"
              v-bind="attrs"
              v-on="on">
              <v-icon left>
                mdi-access-point-plus
              </v-icon>
              Buat Pengumuman
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Buat Pengumuman</span>
            </v-card-title>

            <v-form
              ref="form"
              v-model="validForm">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="title"
                        label="Judul"
                        :rules="cannotEmptyRule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-autocomplete
                        chips
                        clearable
                        multiple
                        deletable-chips
                        v-model="participants"
                        label="Penerima"
                        :items="salesmans"
                        item-text="name"
                        item-value="uid"
                        :rules="arrayCannotEmptyRule"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            @click="toggleSelectAll"
                          >
                            <v-list-item-action>
                              <v-icon :color="participants.length > 0 ? 'indigo darken-4' : ''">
                                {{ icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-textarea
                        v-model="message"
                        label="Isi"
                        :rules="cannotEmptyRule"
                        prepend-icon="mdi-comment"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  :disabled="!validForm"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.date="{ item }">
      {{ item.date.toDate().toLocaleString() }}
    </template>
    <template v-slot:item.participants="{ item }">
      {{
        toParticipantListString(item.participants)
      }}
    </template>
  </v-data-table>
</template>

<script>
import { arrayCannotEmptyRule, cannotEmptyRule } from '@/utils/inputRules'
import { listToString } from '@/utils/participantsString'

export default {
  name: 'AnnouncementTable',
  data: () => ({
    headers: [
      {
        text: 'Waktu',
        value: 'date'
      },
      {
        text: 'Judul',
        value: 'title'
      },
      {
        text: 'Penerima',
        value: 'participants'
      },
      {
        text: 'Isi',
        value: 'message'
      }
    ],
    dialog: false,
    validForm: false,
    cannotEmptyRule: cannotEmptyRule,
    arrayCannotEmptyRule: arrayCannotEmptyRule,
    title: '',
    message: '',
    participants: []
  }),

  computed: {
    announcements () {
      return this.$store.state.announcements
    },
    salesmans () {
      return this.$store.state.salesmans
    },
    selectedAll () {
      return this.participants.length === this.salesmans.length
    },
    selectedSome () {
      return this.participants.length > 0 && !this.selectedAll
    },
    icon () {
      if (this.selectedAll) return 'mdi-close-box'
      if (this.selectedSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },

  watch: {
    dialog (val) {
      val || this.clearAddAnnouncementData()
    }
  },

  methods: {
    toParticipantListString (participants) {
      const participantsName = participants.map(participantId => {
        const salesman = this.salesmans.filter(value => value.uid === participantId)[0]
        if (salesman) return salesman.name
        else return participantId
      })
      return listToString(participantsName)
    },
    toggleSelectAll () {
      this.$nextTick(() => {
        if (this.selectedAll) {
          this.participants = []
        } else {
          this.participants = this.salesmans.map(s => s.uid)
        }
      })
    },
    clearAddAnnouncementData () {
      this.participants = []
      this.title = ''
      this.message = ''
      this.$refs.form.resetValidation()
    },
    save () {
      this.$firebase.firestore().collection('announcements')
        .doc()
        .set({
          title: this.title,
          message: this.message,
          date: new Date(),
          participants: this.participants
        })
        .then(value => {
          this.dialog = false
        })
        .catch()
    }
  }
}
</script>
