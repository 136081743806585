<template>
  <v-container class="pa-8">
    <v-row>
      <v-col>
        <AnnouncementTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AnnouncementTable from '@/components/announcement/AnnouncementTable'

export default {
  name: 'Announcement',
  components: { AnnouncementTable },
  created () {
    this.$store.dispatch('fetchSalesmanDataList', { forceUpdate: false })
    this.$store.dispatch('listenAnnouncements')
  }
}
</script>
