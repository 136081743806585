<template>
  <div>
    <p class="text-h4">Peta Konter</p>
    <GmapMap
      :center="{lat:stores[0].lat, lng:stores[0].long}"
      :options="mapOptions"
      style="width: 100%; height: 500px"
    >
      <gmap-info-window :options="infoOptions" :position="infoPosition" :opened="infoOpened"
                        @closeclick="infoOpened=false">
        <v-container>
          <v-row>
            {{ infoContent.name }}
          </v-row>
          <v-row>
            {{ infoContent.areaCode }}
          </v-row>
          <v-row>
            {{ infoContent.address }}
          </v-row>
        </v-container>
      </gmap-info-window>
      <GmapMarker
        v-for="(item, key) in stores"
        :animation="2"
        :key="key"
        :clickable="true"
        @click="toggleInfo(item, key)"
        :label=item.name
        :position="{lat:item.lat, lng:item.long}"/>
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: 'AllStoreMap',
  data: () => ({
    mapOptions: {
      zoom: 11,
      mapTypeId: 'roadmap',
      streetViewControl: false
    },
    infoPosition: null,
    infoContent: {},
    infoOpened: false,
    infoCurrentKey: null,
    infoOptions: {
      pixelOffset: {
        width: 0,
        height: -40
      }
    }
  }),

  computed: {
    stores () {
      return this.$store.state.stores
    }
  },

  methods: {
    toggleInfo: function (marker, key) {
      this.infoPosition = {
        lat: marker.lat,
        lng: marker.long
      }
      this.infoContent = marker
      if (this.infoCurrentKey === key) {
        this.infoOpened = !this.infoOpened
      } else {
        this.infoOpened = true
        this.infoCurrentKey = key
      }
    }
  }
}
</script>
