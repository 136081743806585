<template>
  <v-container class="pa-8">
    <v-row>
      <v-col>
        <StoreTable/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <PendingStoreTable/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <RejectedStoreTable/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <AllStoreMap/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StoreTable from '@/components/store/StoreTable'
import PendingStoreTable from '@/components/store/PendingStoreTable'
import RejectedStoreTable from '@/components/store/RejectedStoreTable'
import AllStoreMap from '@/components/store/AllStoreMap'

export default {
  name: 'Store',
  components: { AllStoreMap, RejectedStoreTable, PendingStoreTable, StoreTable },
  created () {
    this.$store.dispatch('listenStores')
    this.$store.dispatch('listenPendingStores')
    this.$store.dispatch('listenRejectedStores')
    this.$store.dispatch('listenTransactions')
  }
}
</script>
