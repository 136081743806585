var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.pendingTransactions,"items-per-page":15,"search":_vm.search,"loading-text":"Tunggu sebentar","no-data-text":"Tidak ada transaksi menunggu persetujuan.","no-results-text":"Data tidak ditemukan"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Transaksi Menunggu Persetujuan")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"width":"15%","max-width":"300px"},attrs:{"append-icon":"mdi-magnify","label":"Cari","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.type === 'PAYMENT') ? 'Order' : 'Pembayaran')+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date.toDate().toLocaleDateString())+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toRupiahString (item.value))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.approveTransaction(item)}}},[_vm._v(" Setujui ")]),_c('v-btn',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.rejectTransaction(item)}}},[_vm._v(" Tolak ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }