<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="stores"
      :items-per-page="15"
      :search="search"
      @click:row="openStoreDetails"
      loading-text="Tunggu sebentar"
      no-data-text="Tidak ada data konter."
      no-results-text="Data tidak ditemukan"
      class="elevation-1"
    >

      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Data Konter Ditolak</v-toolbar-title>
          <v-spacer/>
          <v-text-field
            style="width: 15%; max-width: 300px"
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
    </v-data-table>

    <!-- Store Details Dialog -->
    <v-dialog
      v-model="storeDetailsDialog"
      width="70%"
      max-width="50%">
      <v-card>
        <v-row no-gutters>
          <v-img
            height="100%"
            :src="viewedStore.photoUrl"
          />
        </v-row>
        <v-row no-gutters>
          <v-col class="pa-8" style="overflow-y: auto">
            <v-row>
              <v-col cols="8">
                <span class="text-h3">{{ viewedStore.name }}</span>
              </v-col>
              <v-col cols="4" align-self="center" class="text-end">
                <v-rating
                  background-color="warning lighten-1"
                  color="warning"
                  length="5"
                  dense
                  readonly
                  size="32"
                  :value="viewedStore.paymentRating"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <p class="subtitle-2 ma-0">{{ viewedStore.areaCode }}</p>
                <p class="subtitle-1">{{ viewedStore.address }}</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                Sales
              </v-col>
              <v-col cols="9">
                {{ viewedStore.salesmanId }}
              </v-col>
              <v-col cols="2">
                Catatan
              </v-col>
              <v-col cols="9">
                {{ viewedStore.note }}
              </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row no-gutters>
              <v-col cols="12"><p class="text-h5">Pemilik Konter</p></v-col>
              <v-col cols="4" class="d-flex child-flex mr-4">
                <v-img
                  aspect-ratio="1"
                  :src="viewedStore.ownerPhotoUrl"
                  max-width="100%"/>
              </v-col>
              <v-col>
                <p class="text-h6">{{ viewedStore.ownerName }}</p>
                <v-icon class="mr-2">mdi-phone</v-icon>
                <span>{{ viewedStore.ownerPhone }}</span>
              </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row>
              <v-col>
                <GmapMap
                  :center="{lat:viewedStore.lat, lng:viewedStore.long}"
                  :zoom="15"
                  style="width: 100%; height: 500px"
                >
                  <GmapMarker
                    :position="{lat:viewedStore.lat, lng:viewedStore.long}"></GmapMarker>
                </GmapMap>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'RejectedStoreTable',
  data: () => ({
    search: '',
    headers: [
      {
        text: 'ID Agen',
        align: 'start',
        value: 'id'
      },
      {
        text: 'Sales',
        value: 'salesmanId'
      },
      {
        text: 'Kode area',
        value: 'areaCode'
      },
      {
        text: 'Nama konter',
        value: 'name'
      },
      {
        text: 'Pemilik',
        value: 'ownerName'
      }
    ],
    storeDetailsDialog: false,
    viewedStore: {}
  }),

  computed: {
    stores () {
      return this.$store.state.rejectedStores
    }
  },

  methods: {
    openStoreDetails (item, row) {
      this.viewedStore = item
      this.storeDetailsDialog = true
    }
  }
}
</script>
