<template>
  <export-excel
    :data="stores"
    :fields="fields"
    name="Konter.xls">
    <v-btn color="green">
      Export Excel
    </v-btn>
  </export-excel>
</template>

<script>
export default {
  name: 'DownloadStoreExcel',
  props: {
    stores: Array
  },
  data: () => ({
    fields: {
      'ID Konter': 'id',
      Nama: 'name',
      'Kode Area': 'areaCode',
      Alamat: 'address',
      Foto: 'photoUrl',
      'Garis lintang': 'lat',
      'Garis bujur': 'long',
      'ID Sales': 'salesmanId',
      Catatan: 'note',
      'Rating pembayaran': 'paymentRating',
      Pemilik: 'ownerName',
      'No HP pemilik': 'ownerPhone',
      'Foto pemilik': 'ownerPhotoUrl'
    }
  })
}
</script>
