<template>
  <div>
    <v-btn
      color="red"
      @click="generatePdf">
      Export PDF
    </v-btn>
    <vue-html2pdf
      v-show="false"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Transaksi"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf">
      <section slot="pdf-content">
        <v-data-table
          :headers="headers"
          :items="transactions"
          :items-per-page="-1"
          loading-text="Tunggu sebentar"
          no-data-text="Tidak ada data transaksi."
          no-results-text="Data tidak ditemukan"
          hide-default-footer
          class="elevation-1">
        </v-data-table>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'DownloadTransactionsPdf',
  props: {
    transactions: Array
  },
  components: {
    VueHtml2pdf
  },
  data: () => ({
    headers: [
      {
        text: 'ID Agen',
        align: 'start',
        value: 'agentId'
      },
      {
        text: 'ID Sales',
        align: 'start',
        value: 'salesmanId'
      },
      {
        text: 'Tanggal',
        value: 'date'
      },
      {
        text: 'Jenis transaksi',
        value: 'type'
      },
      {
        text: 'Nilai',
        value: 'amount',
        align: 'end'
      },
      {
        text: 'Catatan',
        value: 'note'
      }
    ]
  }),
  methods: {
    generatePdf () {
      this.$refs.html2Pdf.generatePdf()
    }
  }
}
</script>
