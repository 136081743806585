<template>
  <v-data-table
    :headers="headers"
    :items="stores"
    :items-per-page="15"
    :search="search"
    @click:row="openStoreDetails"
    loading-text="Tunggu sebentar"
    no-data-text="Tidak ada data konter."
    no-results-text="Data tidak ditemukan"
    class="elevation-1"
  >

    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Edit Data Konter Menunggu Persetujuan</v-toolbar-title>
        <v-spacer/>
        <v-text-field
          style="width: 15%; max-width: 300px"
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari"
          single-line
          hide-details
        ></v-text-field>
      </v-toolbar>
    </template>

    <template v-slot:item.date="{ item }">
      {{ item.date.toDate().toLocaleDateString() }}
    </template>

    <template v-slot:item.value="{ item }">
      {{ toRupiahString(item.value) }}
    </template>

    <template v-slot:item.paidOff="{ item }">
      {{ item.paidOff ? 'Lunas' : 'Belum lunas' }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn
        small
        color="green"
        class="mr-2"
        @click="approveStore(item)"
      >
        Setujui
      </v-btn>
      <v-btn
        small
        color="red"
        @click="rejectStore(item)"
      >
        Tolak
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'PendingStoreTable',

  data: () => ({
    search: '',
    headers: [
      {
        text: 'ID Agen',
        align: 'start',
        value: 'id'
      },
      {
        text: 'Sales',
        value: 'salesmanId'
      },
      {
        text: 'Kode area',
        value: 'areaCode'
      },
      {
        text: 'Nama konter',
        value: 'name'
      },
      {
        text: 'Pemilik',
        value: 'ownerName'
      },
      {
        text: 'Actions',
        value: 'actions'
      }
    ]
  }),

  computed: {
    stores () {
      return this.$store.state.pendingStores
    }
  },

  methods: {
    openStoreDetails (item, row) {
      this.viewedStore = item
      this.storeDetailsDialog = true
    },

    approveStore (store) {
      this.copyStoreToPermanentDb(store)
        .then(value => this.deletePendingStoreFromDb(store.id))
    },

    rejectStore (store) {
      this.copyStoreToRejectedDb(store)
        .then(value => this.deletePendingStoreFromDb(store.id))
    },

    copyStoreToPermanentDb (store) {
      const storeData = { ...store }
      delete storeData.id
      const id = store.id

      return new Promise(
        (resolve, reject) => {
          this.$firebase.firestore()
            .collection('stores')
            .doc(id)
            .set(storeData)
            .then(value => resolve(value))
            .catch(reason => reject(reason))
        }
      )
    },

    copyStoreToRejectedDb (store) {
      const storeData = { ...store }
      delete storeData.id
      const id = store.id

      return new Promise(
        (resolve, reject) => {
          this.$firebase.firestore()
            .collection('stores_rejected')
            .doc(id)
            .set(storeData)
            .then(value => resolve(value))
            .catch(reason => reject(reason))
        }
      )
    },

    deletePendingStoreFromDb (storeId) {
      this.$firebase.firestore()
        .collection('stores_temporary')
        .doc(storeId)
        .delete()
    }
  }
}
</script>
