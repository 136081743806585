const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const cannotEmptyRule = [
  value => !!value || 'Wajib di isi'
]

const arrayCannotEmptyRule = [
  value => value.length > 0 || 'Wajib di isi'
]

const emailRule = [
  value => !!value || 'Wajib di isi',
  value => (value || '').length <= 20 || 'Maksimal 20 karakter',
  value => {
    return emailPattern.test(value) || 'E-mail invalid'
  }
]

const usernameRule = [
  value => !!value || 'Wajib di isi',
  value => (value || '').length >= 5 || 'Minimal 5 karakter'
]

const salesIdRule = cannotEmptyRule

const salesNameRule = cannotEmptyRule

const passwordRule = [
  value => !!value || 'Wajib di isi',
  value => (value || '').length >= 7 || 'Minimal 7 karakter'
]

export { arrayCannotEmptyRule, cannotEmptyRule, emailRule, usernameRule, salesIdRule, salesNameRule, passwordRule }
