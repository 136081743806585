<template>
  <v-card
    elevation="2"
    tile
    class="pa-8 ma-auto"
    rounded
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-row class="mb-4">
        <span class="text-h4">Login</span>
      </v-row>
      <v-row>
        <v-text-field v-model="username"
                      clearable
                      type="text"
                      :rules="usernameRules"
                      placeholder="Username"></v-text-field>
      </v-row>
      <v-row>
        <v-text-field v-model="password"
                      :rules="passwordRules"
                      :append-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (hidePassword = !hidePassword)"
                      @keyup.enter="login"
                      :type="hidePassword ? 'password' : 'text'"
                      placeholder="Password"></v-text-field>
      </v-row>
      <v-row justify="center" class="mt-2">
        <v-btn
          :disabled="!valid"
          width="100%"
          color="primary"
          @keyup.enter="login"
          @click="login">Login
        </v-btn>
      </v-row>
      <v-row justify="center" class="mt-2">
        <v-alert dismissible dense text transition="scale-transition" :value="loginFailedDialog" type="error"
                 width="100%">
          Login gagal
        </v-alert>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import * as inputValidation from '@/utils/inputRules'

export default {
  name: 'LoginForm',
  data: () => ({
    usernameRules: inputValidation.usernameRule,
    passwordRules: inputValidation.passwordRule,
    loginFailedDialog: false,
    valid: false,
    username: '',
    password: '',
    hidePassword: true
  }),
  methods: {
    login () {
      this.$firebase.auth().signInWithEmailAndPassword(this.username + '@dflash.com', this.password).catch(() => {
        this.loginFailedDialog = true
        console.log(this.loginFailedDialog)
      })
    }
  }
}
</script>
