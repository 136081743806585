<template>
  <v-data-table
    :headers="headers"
    :items="rejectedTransactions"
    :items-per-page="15"
    :search="search"
    loading-text="Tunggu sebentar"
    no-data-text="Tidak ada transaksi ditolak."
    no-results-text="Data tidak ditemukan"
    class="elevation-1"
  >

    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Transaksi Ditolak</v-toolbar-title>
        <v-spacer/>
        <v-text-field
          style="width: 15%; max-width: 300px"
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari"
          single-line
          hide-details
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.type="{ item }">
      {{ (item.type === 'PAYMENT') ? 'Order' : 'Pembayaran'}}
    </template>
    <template v-slot:item.date="{ item }">
      {{item.date.toDate().toLocaleDateString()}}
    </template>
    <template v-slot:item.value="{ item }">
      {{ toRupiahString(item.value) }}
    </template>
  </v-data-table>
</template>

<script>
import { toRupiahString } from '@/utils/currencyString'

export default {
  name: 'RejectedTransactionTable',
  data: () => ({
    search: '',
    headers: [
      {
        text: 'ID Agen',
        align: 'start',
        value: 'agentId'
      },
      {
        text: 'ID Sales',
        align: 'start',
        value: 'salesmanId'
      },
      {
        text: 'Tanggal',
        value: 'date'
      },
      {
        text: 'Jenis transaksi',
        value: 'type'
      },
      {
        text: 'Nilai',
        value: 'amount'
      },
      {
        text: 'Lunas',
        value: 'paidOff'
      },
      {
        text: 'Catatan',
        value: 'note'
      }
    ]
  }),

  computed: {
    rejectedTransactions () {
      return this.$store.getters.rejectedTransactions
    }
  },

  methods: {
    toRupiahString (value) {
      return toRupiahString(value)
    }
  }
}
</script>
