export default {
  uid: null,
  user: null,
  salesmans: [],
  admins: [],
  transactions: [],
  stores: [],
  pendingStores: [],
  rejectedStores: [],
  announcements: [],
  listeningTo: {
    announcements: false
  }
}
