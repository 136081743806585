<template>
  <v-container class="pa-8">
    <v-row>
      <v-col>
        <h1>Daftar Transaksi</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <TransactionTable/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <PendingTransactionTable/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <RejectedTransactionTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TransactionTable from '@/components/transaction/TransactionTable'
import PendingTransactionTable from '@/components/transaction/PendingTransactionTable'
import RejectedTransactionTable from '@/components/transaction/RejectedTransactionTable'

export default {
  name: 'Transaction',
  components: {
    RejectedTransactionTable,
    PendingTransactionTable,
    TransactionTable
  },
  created () {
    this.listenTransactionData(false)
  },
  methods: {
    listenTransactionData () {
      this.$store.dispatch('listenTransactions')
    }
  }
}
</script>
