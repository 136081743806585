import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/views/Dashboard'
import Login from '@/views/Login'
import Main from '@/views/Main'
import Admin from '@/views/Dashboard/Admin'
import Sales from '@/views/Dashboard/Sales'
import Store from '@/views/Dashboard/Store'
import Transaction from '@/views/Dashboard/Transaction'
import Announcement from '@/views/Dashboard/Announcement'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      {
        path: '/',
        component: Transaction
      },
      {
        path: 'admin',
        component: Admin
      },
      {
        path: 'sales',
        component: Sales
      },
      {
        path: 'transaction',
        component: Transaction
      },
      {
        path: 'store',
        component: Store
      },
      {
        path: 'announcements',
        component: Announcement
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  routes
})

export default router
