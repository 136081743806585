<template>
  <v-container class="body fill-height align-start align-content-start blue justify-center" fluid>
    <v-row class="ma-0 mt-6 mx-auto justify-center">
      <span class="display-3 white--text font-weight-bold">DFlash</span>
      <span class="display-3 white--text">Admin</span>
    </v-row>
    <v-row class="my-12 justify-center">
      <v-col cols="12" sm="7" md="5" lg="4" xl="3">
        <LoginForm class="ma-auto"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from '@/components/LoginForm'

export default {
  components: { LoginForm },
  data () {
    return {
      backgroundStyle: {
        backgroundImage: 'linear-gradient(to right, #2193b0, #6dd5ed)',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover'
      }
    }
  },
  created () {
    this.$firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        console.log('user signed in')
        this.$router.push('/')
      } else {
        // No user is signed in.
        console.log('not signed in')
      }
    })
  }
}
</script>
