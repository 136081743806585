<template>
  <v-data-table
    :headers="headers"
    :items="salesmans"
    :items-per-page="15"
    :loading="this.isLoading"
    loading-text="Tunggu sebentar"
    no-data-text="Tidak ada sales."
    no-results-text="Data tidak ditemukan"
    class="elevation-1"
  >

    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Daftar Sales</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>

        <!--Refresh button-->
        <v-btn
          icon
          color="blue"
          class="mr-2"
          @click="fetchSalesmanData(true)"
        >
          <v-icon>mdi-cached</v-icon>
        </v-btn>

        <!--Add and Edit dialog-->
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Tambahkan Sales
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-form
              v-model="validForm">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.uid"
                        :disabled="editedIndex > -1"
                        label="ID Sales"
                        :rules="idRules"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.name"
                        label="Nama Sales"
                        :rules="nameRules"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="editedIndex <= -1"
                    >
                      <v-text-field
                        v-model="editedItem.password"
                        label="Password"
                        :rules="passwordRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeAddEditDialog"
                >
                  Batal
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  :disabled="!validForm"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <!--Delete dialog-->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Anda yakin ingin menghapus sales ini?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDeleteDialog">Batal</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        @click="showDeleteDialog(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import * as inputValidation from '@/utils/inputRules'

export default {
  name: 'SalesManTable',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    isLoading: false,
    passwordRules: inputValidation.passwordRule,
    nameRules: inputValidation.salesNameRule,
    idRules: inputValidation.salesIdRule,
    validForm: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'uid'
      },
      {
        text: 'Nama Sales',
        value: 'name'
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false
      }
    ],
    editedIndex: -1,
    editedItem: {
      uid: '',
      name: '',
      password: ''
    },
    defaultItem: {
      uid: '',
      name: '',
      password: ''
    }
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Tambahkan Sales' : 'Edit Sales'
    },
    salesmans () {
      return this.$store.state.salesmans
    }
  },

  watch: {
    dialog (val) {
      val || this.closeAddEditDialog()
    },
    dialogDelete (val) {
      val || this.closeDeleteDialog()
    }
  },

  created () {
    this.fetchSalesmanData(false)
  },

  methods: {
    showAddEditDialog (item) {
      this.editedIndex = this.salesmans.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    showDeleteDialog (item) {
      this.editedIndex = this.salesmans.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    closeAddEditDialog () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDeleteDialog () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    deleteItemConfirm () {
      this.deleteSalesAccount()
      this.closeDeleteDialog()
    },

    fetchSalesmanData (forceUpdate) {
      this.$store.dispatch('fetchSalesmanDataList', { forceUpdate: forceUpdate })
    },

    save () {
      this.createSalesAccount()
      this.closeAddEditDialog()
    },

    createSalesAccount () {
      this.isLoading = true
      const createSales = this.$firebase.functions('asia-southeast2').httpsCallable('createSalesAccount')
      createSales({
        uid: this.editedItem.uid,
        name: this.editedItem.name,
        password: this.editedItem.password
      })
        .then(result => {
          console.log('Success creating account:', result.data)
          this.fetchSalesmanData(true)
          this.isLoading = false
        })
        .catch(error => {
          console.error(error)
          this.isLoading = false
        })
    },

    deleteSalesAccount () {
      this.isLoading = true
      const deleteSales = this.$firebase.functions('asia-southeast2').httpsCallable('deleteSalesAccount')
      deleteSales({
        uid: this.editedItem.uid,
        name: this.editedItem.name,
        password: this.editedItem.password
      })
        .then(result => {
          console.log('Success deleting account', result)
          this.fetchSalesmanData(true)
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
        })
    }
  }
}
</script>
