<template>
  <div>
    <v-btn
      color="red"
      @click="generatePdf">
      Export PDF
    </v-btn>
    <vue-html2pdf
      v-show="false"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Konter"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf">
      <section slot="pdf-content">
        <v-data-table
          :headers="headers"
          :items="stores"
          :items-per-page="-1"
          loading-text="Tunggu sebentar"
          no-data-text="Tidak ada data konter."
          no-results-text="Data tidak ditemukan"
          hide-default-footer
          class="elevation-1">
          <template v-slot:item.paymentRating="{ item }">
            <v-rating
              background-color="warning lighten-1"
              color="warning"
              length="5"
              dense
              readonly
              size="32"
              :value="item.paymentRating"
            />
          </template>
        </v-data-table>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'DownloadStorePdf',
  props: {
    stores: Array
  },
  components: {
    VueHtml2pdf
  },
  data: () => ({
    headers: [
      {
        text: 'ID Konter',
        align: 'start',
        value: 'id',
        groupable: false
      },
      {
        text: 'Sales',
        value: 'salesmanId'
      },
      {
        text: 'Nama konter',
        value: 'name'
      },
      {
        text: 'Kode area',
        value: 'areaCode'
      },
      {
        text: 'Alamat',
        value: 'address'
      },
      {
        text: 'Catatan',
        value: 'note'
      },
      {
        text: 'Rating pembayaran',
        value: 'paymentRating'
      },
      {
        text: 'Pemilik',
        value: 'ownerName'
      },
      {
        text: 'No HP Pemilik',
        value: 'ownerPhone'
      }
    ]
  }),
  methods: {
    generatePdf () {
      this.$refs.html2Pdf.generatePdf()
    }
  }
}
</script>
