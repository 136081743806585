import Vue from 'vue'
import Vuex from 'vuex'
import firebase from '@/services/firebaseConfig'
import defaultState from '@/state/defaultState'

Vue.use(Vuex)

const store = new Vuex.Store({
  state () {
    return defaultState
  },
  mutations: {
    SET_UID (state, uid) {
      state.uid = uid
    },
    SET_USER_DATA (state, data) {
      state.user = data
    },

    ADD_SALESMAN_DATA (state, data) {
      state.salesmans.push(data)
    },
    CLEAR_SALESMAN_DATA (state, data) {
      state.salesmans = []
    },

    ADD_ADMIN_DATA (state, data) {
      state.admins.push(data)
    },
    CLEAR_ADMIN_DATA (state, data) {
      state.admins = []
    },

    ADD_TRANSACTION_DATA (state, data) {
      state.transactions.push(data)
    },
    CLEAR_TRANSACTION_DATA (state, data) {
      state.transactions = []
    },

    ADD_STORE_DATA (state, data) {
      state.stores.push(data)
    },
    CLEAR_STORE_DATA (state, data) {
      state.stores = []
    },

    ADD_PENDING_STORE_DATA (state, data) {
      state.pendingStores.push(data)
    },
    CLEAR_PENDING_STORE_DATA (state, data) {
      state.pendingStores = []
    },

    ADD_REJECTED_STORE_DATA (state, data) {
      state.rejectedStores.push(data)
    },
    CLEAR_REJECTED_STORE_DATA (state, data) {
      state.rejectedStores = []
    },

    ADD_ANNOUNCEMENT_DATA (state, data) {
      state.announcements.push(data)
    },
    CLEAR_ANNOUNCEMENTS_DATA (state, data) {
      state.announcements = []
    },

    RESET (state) {
      Object.keys(defaultState).forEach(key => {
        state[key] = defaultState[key]
      })
    }
  },

  getters: {
    approvedTransactions: state => {
      return state.transactions.filter(value => {
        return value.status === 'APPROVED' || value.status === 'approved'
      })
    },
    pendingTransactions: state => {
      return state.transactions.filter(value => {
        return value.status === '' || value.status === null || value.status === undefined || value.status === 'PENDING'
      })
    },
    rejectedTransactions: state => {
      return state.transactions.filter(value => {
        return value.status === 'REJECTED'
      })
    }
  },

  actions: {
    clearStoreData (context) {
      context.commit('RESET')
      localStorage.clear()
    },

    fetchUid (context) {
      const uid = firebase.auth().currentUser.uid
      context.commit('SET_UID', uid)
    },

    fetchUserData (context) {
      if (localStorage.user) {
        context.commit('SET_USER_DATA', JSON.parse(localStorage.user))
      } else {
        firebase.firestore()
          .collection('admins')
          .doc(context.state.uid)
          .get()
          .then((adminData) => {
            if (adminData.exists) {
              context.commit('SET_USER_DATA', adminData.data())
              localStorage.user = JSON.stringify(adminData.data())
            } else {
              context.commit('SET_USER_DATA', 'invalid')
              console.error('Non admin logged in')
            }
          })
      }
    },

    fetchSalesmanDataList (context, { forceUpdate }) {
      if (forceUpdate || (!forceUpdate && context.state.salesmans.length === 0)) {
        firebase.firestore()
          .collection('salesmans')
          .get()
          .then((salesmans) => {
            context.commit('CLEAR_SALESMAN_DATA')
            salesmans.forEach((salesman) => {
              const data = salesman.data()
              data.uid = salesman.id
              context.commit('ADD_SALESMAN_DATA', data)
            })
          })
      }
    },

    fetchAdminDataList (context, { forceUpdate }) {
      if (forceUpdate || (!forceUpdate && context.state.admins.length === 0)) {
        firebase.firestore()
          .collection('admins')
          .get()
          .then((admins) => {
            context.commit('CLEAR_ADMIN_DATA')
            admins.forEach((admin) => {
              const data = admin.data()
              data.username = admin.id
              context.commit('ADD_ADMIN_DATA', data)
            })
          })
      }
    },

    listenTransactions (context) {
      if (context.state.listeningTo.transactions) return null

      firebase.firestore()
        .collection('transactions')
        .onSnapshot(snapshot => {
          context.commit('CLEAR_TRANSACTION_DATA')
          snapshot.forEach((transaction) => {
            const data = transaction.data()
            data.id = transaction.id
            context.commit('ADD_TRANSACTION_DATA', data)
          })
        })
      context.state.listeningTo.transactions = true
    },

    listenStores (context) {
      firebase.firestore()
        .collection('stores')
        .onSnapshot(snapshot => {
          context.commit('CLEAR_STORE_DATA')
          snapshot.forEach(storeSnapshot => {
            const store = storeSnapshot.data()
            store.id = storeSnapshot.id
            context.commit('ADD_STORE_DATA', store)
          })
        })
    },

    listenPendingStores (context) {
      firebase.firestore()
        .collection('stores_temporary')
        .onSnapshot(snapshot => {
          context.commit('CLEAR_PENDING_STORE_DATA')
          snapshot.forEach(storeSnapshot => {
            const store = storeSnapshot.data()
            store.id = storeSnapshot.id
            context.commit('ADD_PENDING_STORE_DATA', store)
          })
        })
    },

    listenRejectedStores (context) {
      firebase.firestore()
        .collection('stores_rejected')
        .onSnapshot(snapshot => {
          context.commit('CLEAR_REJECTED_STORE_DATA')
          snapshot.forEach(storeSnapshot => {
            const store = storeSnapshot.data()
            store.id = storeSnapshot.id
            context.commit('ADD_REJECTED_STORE_DATA', store)
          })
        })
    },

    listenAnnouncements (context) {
      if (context.state.listeningTo.announcements) return null

      firebase.firestore()
        .collection('announcements')
        .onSnapshot(snapshot => {
          context.commit('CLEAR_ANNOUNCEMENTS_DATA')
          snapshot.forEach(announcementSnapshot => {
            const announcement = announcementSnapshot.data()
            announcement.id = announcementSnapshot.id
            context.commit('ADD_ANNOUNCEMENT_DATA', announcement)
          })
        })
      context.state.listeningTo.announcements = true
    }
  }
})

export default store
