var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.announcements,"items-per-page":15,"loading-text":"Tunggu sebentar","no-data-text":"Tidak ada pengumuman.","no-results-text":"Data tidak ditemukan"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Pengumuman")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary"},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-access-point-plus ")]),_vm._v(" Buat Pengumuman ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Buat Pengumuman")])]),_c('v-form',{ref:"form",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Judul","rules":_vm.cannotEmptyRule},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"chips":"","clearable":"","multiple":"","deletable-chips":"","label":"Penerima","items":_vm.salesmans,"item-text":"name","item-value":"uid","rules":_vm.arrayCannotEmptyRule},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleSelectAll}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.participants.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.participants),callback:function ($$v) {_vm.participants=$$v},expression:"participants"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Isi","rules":_vm.cannotEmptyRule,"prepend-icon":"mdi-comment"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.validForm},on:{"click":_vm.save}},[_vm._v(" Simpan ")])],1)],1)],1)],1)],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date.toDate().toLocaleString())+" ")]}},{key:"item.participants",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toParticipantListString(item.participants))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }