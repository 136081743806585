<template>
  <v-app-bar
    elevate-on-scroll
    dark
    color="blue"
    app>

    <v-toolbar-title>Dashboard</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu
      left
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          @click="logout()"
        >
          <v-list-item-title>
            Keluar
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: 'AppBar',
  methods: {
    logout () {
      this.$firebase.auth().signOut().then(() => {
        this.$store.dispatch('clearStoreData')
        this.$router.replace('/login').catch()
      }).catch((err) => {
        console.log('Failed to logout: ' + err.message())
      })
    }
  }
}
</script>
