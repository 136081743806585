<template>
  <v-container class="pa-8">
    <v-row>
      <v-col cols="12">
        <AdminTable></AdminTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdminTable from '@/components/admin/AdminTable'

export default {
  name: 'Admin',
  components: { AdminTable },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  created () {
    if (this.user.role !== 'administrator') this.$router.back()
  }
}
</script>
