import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyDLa6VecbqrzWlPMNLigInNcZLAGFdgM8c',
  authDomain: 'dflash-3b462.firebaseapp.com',
  databaseURL: 'https://dflash-3b462.firebaseio.com',
  projectId: 'dflash-3b462',
  storageBucket: 'dflash-3b462.appspot.com',
  messagingSenderId: '345412785972',
  appId: '1:345412785972:web:d82b22c6d16cd24148f761',
  measurementId: 'G-FK835376HX'
}

export default firebase.initializeApp(firebaseConfig)
